<template>
  <v-container fluid class="pt-0 px-0">
    <v-row>
      <v-col cols="1" md="1" class="px-0 pt-2 pb-2">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="24"
              v-on="on"
              tabindex="-1"
              @click="volver(true)"
            >
              {{ volverIcon }}
            </v-icon>
          </template>
          <span>Atrás</span>
        </v-tooltip>
        <!-- <GoBackBtn :routeToGo="routeToGo" :tabId="false" /> -->
      </v-col>
      <v-col cols="11" md="8" class="pb-0 pt-0 px-0" tabindex="-1">
        <PageHeader :title="title" class="pt-0 pb-0" />
        <!-- <span class="primary--text font-weight-medium py-0 pl-2"
          >Carga de facturas</span
        > -->
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="p-0 pt-2"
        v-if="numeroExpediente > 0"
        tabindex="0"
      >
        <span class="font-weight-medium text-right">
          Expediente N°:{{ numeroExpediente }}
        </span></v-col
      >
      <v-col
        cols="12"
        class="p-0 pb-2"
        v-if="tieneAsientoContable"
        tabindex="0"
      >
        <span class="error--text"
          >La factura tiene asiento contable, no se puede modificar.</span
        >
      </v-col>
      <v-col
        cols="12"
        class="p-0 pb-2"
        v-if="fecContMenorFecCierre"
        tabindex="0"
      >
        <span class="error--text"
          >El comprobante no se puede modificar por estar cerrado el periodo
          contable.</span
        >
      </v-col>
    </v-row>
    <v-form
      ref="cargaFacturasForm"
      form="cargaFacturasForm"
      v-model="isFormValid"
    >
      <v-row>
        <!-- EMPRESA -->
        <v-col class="p-0 " cols="12" md="4">
          <v-autocomplete
            v-model="empresaSelected"
            return-object
            :items="empresas"
            outlined
            item-value="id"
            :rules="rules.required"
            :disabled="movProvId != null"
            tabindex="1"
            :filled="movProvId != null"
            @change="
              {
                cleanArrays(),
                  setDatosEmpresa(),
                  setImputacionContableEmpresa(),
                  getTiposComprobantes(),
                  getLetraComprobanteByProvEntFacId();
              }
            "
            item-text="value"
            clearable
            ref="btnComboEmpresas"
            id="btnComboEmpresas"
            hide-details="auto"
            dense
            label="Empresa"
          >
          </v-autocomplete>
        </v-col>
        <!-- PROVEEDOR -->
        <v-col cols="12" md="3" class="p-0 pl-1 ">
          <v-text-field
            type="text"
            outlined
            tabindex="2"
            dense
            ref="codigoProveedor"
            id="codigoProveedor"
            label="Código proveedor"
            :clearable="movProvId == null"
            :disabled="movProvId != null"
            :filled="movProvId != null"
            v-model.trim="provCod"
            :rules="rules.required.concat([rules.maxLength(provCod, 10)])"
            @change="
              {
                cleanArrays(), buscarProveedor();
              }
            "
            autocomplete="off"
            hide-details=""
          >
            <template v-slot:append-outer>
              <v-tooltip top max-width="50%">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    tabindex="-1"
                    @click="toggleModalBusquedaProv"
                    v-if="movProvId == null"
                  >
                    {{ searchIcon }}
                  </v-icon>
                </template>
                <span>Buscar proveedor por nombre, CUIT o código</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <!-- DATOS PROVEEDOR -->

        <v-col cols="12" md="5" class="p-0 pl-1 ">
          <v-text-field
            type="text"
            outlined
            dense
            filled
            disabled
            :rules="rules.required"
            readonly
            label="Proveedor"
            hide-details=""
            :loading="proveedoresLoading"
            v-model.trim="provNom"
            autocomplete="off"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <!-- TIPO DE COMPROBANTE -->
      <v-row>
        <v-col cols="12" md="2" class="p-0 pt-1">
          <v-autocomplete
            v-model="tipoComprobanteSelected"
            :items="tiposComprobantes"
            ref="tipoComprobante"
            tabindex="3"
            outlined
            v-if="movProvId == null"
            :clearable="movProvId == null"
            :disabled="movProvId != null"
            :filled="movProvId != null"
            :rules="rules.required"
            item-value="id"
            item-text="value"
            @input="
              {
                setDatosTipoComprobante(), cleanArrays();
              }
            "
            dense
            label="Tipo comprobante"
            hide-details=""
          >
          </v-autocomplete>
          <v-text-field
            outlined
            v-else
            dense
            v-model="tipoComprobanteNom"
            hide-details=""
            label="Tipo comprobante"
            :clearable="movProvId != null"
            :disabled="movProvId != null"
            :filled="movProvId != null"
            style="font-size: 12px;"
          >
          </v-text-field>
        </v-col>
        <!-- FECHA COMPROBANTE -->

        <v-col class="p-0 pl-1 pt-1">
          <v-menu
            ref="fecha"
            v-model="menuFecha"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fechaSelected"
                label="Fecha"
                hint="Formato DD/MM/AAAA"
                @blur="fecha = helpers.parseDateToIso(fechaSelected)"
                outlined
                tabindex="4"
                autocomplete="not"
                :hide-details="!hasValidationError"
                dense
                @change="updateFechas()"
                clearable
                v-mask="'##/##/####'"
                v-bind="attrs"
                v-on="on"
                :rules="rules.required.concat(rules.validDate)"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fecha"
              no-title
              @change="fechaSelected = helpers.formatDate(fecha)"
              @input="menuFecha = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <!-- FECHA VENCIMIENTO -->
        <v-col class="p-0 pl-1 pt-1">
          <v-menu
            ref="fechaVencimiento"
            v-model="menuFechaVencimiento"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fechaVencimientoSelected"
                label="Fecha venc."
                hint="Formato DD/MM/AAAA"
                @blur="
                  fechaVencimiento = helpers.parseDateToIso(
                    fechaVencimientoSelected
                  )
                "
                tabindex="5"
                outlined
                autocomplete="not"
                :hide-details="!hasValidationError"
                dense
                clearable
                v-mask="'##/##/####'"
                v-bind="attrs"
                v-on="on"
                :rules="rules.required.concat(rules.validDate)"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaVencimiento"
              no-title
              @change="
                fechaVencimientoSelected = helpers.formatDate(fechaVencimiento)
              "
              @input="menuFechaVencimiento = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <!-- FECHA CONTABLE -->
        <v-col class="p-0 pl-1 pt-1">
          <v-menu
            ref="fechaContable"
            v-model="menuFechaContable"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fechaContableSelected"
                label="Fecha cble."
                hint="Formato DD/MM/AAAA"
                @blur="
                  fechaContable = helpers.parseDateToIso(fechaContableSelected)
                "
                tabindex="6"
                outlined
                autocomplete="not"
                :hide-details="!hasValidationError"
                dense
                clearable
                v-mask="'##/##/####'"
                v-bind="attrs"
                v-on="on"
                :rules="rules.required.concat(rules.validDate)"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaContable"
              no-title
              @change="
                fechaContableSelected = helpers.formatDate(fechaContable)
              "
              @input="menuFechaContable = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <!-- PERIODO -->
        <v-col cols="12" md="1" class="p-0 pl-1 pt-1">
          <v-text-field
            v-model="periodo"
            :hide-details="!hasValidationError"
            tabindex="7"
            dense
            hint="Formato AAAAMM"
            :rules="rules.periodoYyyyMm.concat(rules.required)"
            outlined
            v-mask="'######'"
            label="Período"
          >
          </v-text-field>
        </v-col>
        <!-- LETRA -->
        <v-col cols="12" md="1" class="p-0 pl-1 pt-1">
          <v-text-field
            v-model="letraComprobante"
            :hide-details="true"
            :rules="rules.required"
            dense
            tabindex="8"
            outlined
            @change="
              getTipoCompNumAutomatico(
                tipoComprobanteSelected,
                letraComprobante,
                sucursalComprobante
              )
            "
            :disabled="
              parametroGeneralBloqueaLetraComp ||
                (tieneNumeradorAutomatico && movProvId != null)
            "
            :filled="
              parametroGeneralBloqueaLetraComp ||
                (tieneNumeradorAutomatico && movProvId != null)
            "
            v-mask="'A'"
            label="Letra"
          >
          </v-text-field>
        </v-col>
        <!-- SUCURSAL -->
        <v-col class="p-0 pl-1 pt-1" cols="12" md="1">
          <v-text-field
            v-model="sucursalComprobante"
            :hide-details="true"
            tabindex="9"
            dense
            @change="
              getTipoCompNumAutomatico(
                tipoComprobanteSelected,
                letraComprobante,
                sucursalComprobante
              )
            "
            outlined
            :rules="[
              rules.decimalGreaterThanZero(parseInt(sucursalComprobante))
            ]"
            label="Sucursal"
            v-mask="'#####'"
            :disabled="tieneNumeradorAutomatico && movProvId != null"
            :filled="tieneNumeradorAutomatico && movProvId != null"
          >
          </v-text-field>
        </v-col>
        <!-- NUMERO COMPROBANTE -->
        <v-col class="p-0 pl-1 pt-1" cols="12" md="2">
          <v-text-field
            v-model="numeroComprobante"
            :hide-details="true"
            tabindex="10"
            dense
            :disabled="tieneNumeradorAutomatico"
            outlined
            :rules="
              tieneNumeradorAutomatico
                ? []
                : [rules.decimalGreaterThanZero(numeroComprobante)]
            "
            :filled="tieneNumeradorAutomatico"
            label="N°"
            v-mask="'#########'"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="p-0 pt-1" cols="12" md="4">
          <v-autocomplete
            v-model="tipoOperacionSelected"
            :items="tiposOperacion"
            item-value="id"
            item-text="value"
            tabindex="11"
            outlined
            clearable
            dense
            :hide-details="true"
            label="Tipo de operación"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" class="p-0 pt-1 pl-1">
          <v-autocomplete
            v-model="monedaSelected"
            :items="monedas"
            @change="getCotizacion()"
            return-object
            :disabled="bloqueaMoneda || movProvId != null"
            :filled="bloqueaMoneda || movProvId != null"
            v-if="parametroGeneralMultimoneda"
            item-value="id"
            item-text="value"
            outlined
            tabindex="12"
            :rules="rules.required"
            clearable
            dense
            label="Moneda"
            hide-details=""
          >
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="p-0 pt-1 pl-1"
          v-if="monedaSelected != null && monedaPorDefecto != monedaSelected.id"
        >
          <currency-input
            v-model="cotizacion"
            label="Cotización"
            :options="currencyOptions"
            :loading="cotizacionLoading"
            :rules="
              monedaSelected != null && monedaPorDefecto != monedaSelected.id
                ? rules.required
                : []
            "
            :hideDetails="'true'"
            :tabindex="'13'"
          ></currency-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          :md="tipoCompLlevaImpContable ? 7 : 12"
          class="pl-4 pt-1 pr-4"
        >
          <!-- DETALLE ALICUOTAS IVA -->
          <v-row>
            <v-col cols="12" class="p-0">
              <v-data-table
                :headers="detalleAlicuotasIvaHeaders"
                :items="detalleAlicuotasIvaItems"
                height="100%"
                fixed-header
                class="elevation-1"
                hide-default-footer
                dense
                item-key="id"
              >
                <template v-slot:top>
                  <v-row>
                    <v-col cols="12" md="6" class="pb-0 pt-2">
                      <span class="primary--text font-weight-medium py-0 pl-2"
                        >Detalle Alícuotas IVA</span
                      >
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0 pt-2 pr-6 text-right">
                      <v-btn
                        ref="btnToggleNuevoDetalleAlicuotasIva"
                        small
                        @click="toggleNuevoDetalleAlicuotasIva"
                        tabindex="14"
                        :disabled="tipoComprobanteSelected == null"
                        class="py-0"
                        color="primary"
                      >
                        Nuevo
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="editDetalleAlicuota(item)"
                      >
                        {{ editIcon }}
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="deleteDetalleAlicuota(item)"
                      >
                        {{ deleteIcon }}
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.neto`]="{ item }">
                  <span>{{ helpers.floatToMoneyString(item.neto) }}</span>
                </template>
                <template v-slot:[`item.iva`]="{ item }">
                  <span>{{ helpers.floatToMoneyString(item.iva) }}</span>
                </template>
                <template v-slot:[`item.total`]="{ item }">
                  <span>{{ helpers.floatToMoneyString(item.total) }}</span>
                </template>
              </v-data-table>
            </v-col>
            <!-- SUMA DE CONCEPTOS PERCEPCIONES IMPUESTOS -->
            <v-col cols="12" md="12" class="p-0 pt-1">
              <v-card class="p-3 pt-2" elevation="4">
                <v-row>
                  <v-col
                    cols="12"
                    :md="tipoCompLlevaImpContable ? 6 : 4"
                    class="pt-1 pb-1 px-2"
                  >
                    <currency-input
                      v-model="netoDetallesAlicuotasIva"
                      label="Neto"
                      :options="currencyOptions"
                      filled
                      :rules="[]"
                      disabled
                      :readonly="true"
                      :hideDetails="'true'"
                    ></currency-input>
                  </v-col>
                  <v-col
                    cols="12"
                    :md="tipoCompLlevaImpContable ? 6 : 4"
                    class="pt-1 pb-1  px-2"
                  >
                    <currency-input
                      v-model="ivaDetallesAlicuotasIva"
                      label="IVA"
                      disabled
                      filled
                      :options="currencyOptions"
                      :rules="[]"
                      :readonly="true"
                      :hideDetails="'true'"
                    ></currency-input>
                  </v-col>
                  <v-col
                    cols="12"
                    :md="tipoCompLlevaImpContable ? 6 : 4"
                    class="pt-1 pb-1 px-2"
                  >
                    <currency-input
                      v-model="percepcionIVA"
                      label="Percepción IVA"
                      :disabled="letraComprobante == 'C'"
                      :filled="letraComprobante == 'C'"
                      :tabindex="'15'"
                      :options="currencyOptions"
                      :rules="rules.requiredAcceptZeroDecimal"
                      :hideDetails="'true'"
                    ></currency-input>
                  </v-col>
                  <v-col
                    cols="12"
                    :md="tipoCompLlevaImpContable ? 6 : 4"
                    class="pt-1 pb-1 px-2"
                    v-if="!empresaMultijurisdiccion"
                  >
                    <currency-input
                      v-model="percepcionIIBB"
                      label="Percep. IIBB"
                      :options="currencyOptions"
                      :tabindex="'16'"
                      :readonly="false"
                      :disabled="false"
                      :filled="false"
                      :rules="
                        !empresaMultijurisdiccion
                          ? rules.requiredAcceptZeroDecimal
                          : []
                      "
                      :hideDetails="'true'"
                    ></currency-input>
                  </v-col>
                  <v-col
                    cols="12"
                    :md="tipoCompLlevaImpContable ? 5 : 3"
                    class="pt-1 pb-1 pl-2 px-0"
                    v-else
                  >
                    <currency-input
                      v-model="totalPercIIBB"
                      label="Percep. IIBB"
                      :options="currencyOptions"
                      :readonly="true"
                      :disabled="true"
                      :filled="true"
                      :rules="[]"
                      :hideDetails="'true'"
                    ></currency-input>
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                    class="pt-2  pl-2 pr-2"
                    v-if="empresaMultijurisdiccion"
                  >
                    <v-btn
                      x-small
                      ref="percIIBB"
                      tabindex="17"
                      icon
                      @click="toggleModalPercIIBB"
                      :disabled="provId == null || entFacId == null"
                      v-if="empresaMultijurisdiccion"
                    >
                      <v-icon>
                        {{ editIcon }}
                      </v-icon>
                    </v-btn>
                  </v-col>

                  <v-col
                    cols="12"
                    :md="tipoCompLlevaImpContable ? 6 : 4"
                    class="pt-1 pb-1  px-2"
                  >
                    <currency-input
                      v-model="impInternos"
                      :tabindex="'18'"
                      :id="'impInternos'"
                      label="Imp. Internos"
                      :options="currencyOptions"
                      :rules="rules.requiredAcceptZeroDecimal"
                      :hideDetails="'true'"
                    ></currency-input>
                  </v-col>
                  <v-col
                    cols="12"
                    :md="tipoCompLlevaImpContable ? 6 : 4"
                    class="pt-1 pb-1  px-2"
                  >
                    <currency-input
                      v-model="otrosConceptos"
                      label="Otros conceptos"
                      :tabindex="'19'"
                      :options="currencyOptions"
                      :rules="rules.requiredAcceptZeroDecimal"
                      :hideDetails="'true'"
                    ></currency-input>
                  </v-col>
                  <v-col cols="12" :md="6" class="pt-1 pb-0 px-4">
                    <span class="font-weight-medium text-right">
                      Total:
                      {{
                        helpers.floatToMoneyString(
                          totalDetallesAlicuotasIva,
                          siglaMonedaSelected
                        )
                      }}</span
                    >
                  </v-col>

                  <v-col cols="12" :md="6" class="pt-1 pb-0 px-4">
                    <span class="font-weight-medium text-right">
                      Total imputable:
                      {{
                        helpers.floatToMoneyString(
                          totalImputable,
                          monedaSelected != null &&
                            monedaSelected.id != monedaPorDefecto
                            ? siglaMonedaPorDefecto
                            : ""
                        )
                      }}</span
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <!-- BARRA LATERAL DERECHA  -->
        <v-col
          cols="12"
          md="5"
          class="p-0 pt-1 pl-5 pr-4"
          v-if="tipoCompLlevaImpContable"
        >
          <v-card class="p-0">
            <v-row>
              <!-- IMPUTACION CONTABLE -->
              <v-col cols="12" md="12" class="p-0 ">
                <v-data-table
                  :headers="imputacionContableHeaders"
                  :items="imputacionContableItems"
                  fixed-header
                  class="elevation-1"
                  hide-default-footer
                  dense
                  item-key="id"
                >
                  <template v-slot:top>
                    <v-row>
                      <v-col cols="12" md="6" class="pb-0 pt-2">
                        <span class="primary--text py-0 pl-2 font-weight-medium"
                          >Imputación contable</span
                        >
                      </v-col>
                      <v-col cols="12" md="6" class="pb-0 pt-2 pr-5 text-right">
                        <v-btn
                          small
                          @click="toggleNuevoImputacionContable"
                          class="py-0"
                          color="primary"
                          tabindex="20"
                          :disabled="datosImputacionContable == null"
                        >
                          {{
                            imputacionContableItems.length > 0 ||
                            centroCostosItems.length > 0
                              ? "Modificar"
                              : "Nuevo"
                          }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:[`item.importe`]="{ item }">
                    <span>{{ helpers.floatToMoneyString(item.importe) }}</span>
                  </template>
                  <template v-slot:footer>
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                        class="pt-1 pb-2 pl-0 pr-6 text-right"
                      >
                        <span class="font-weight-medium text-right">
                          Total imputación contable:
                          {{
                            helpers.floatToMoneyString(
                              totalImputacionContable,
                              monedaSelected != null &&
                                monedaSelected.id != monedaPorDefecto
                                ? siglaMonedaPorDefecto
                                : ""
                            )
                          }}</span
                        >
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
              <!-- CENTRO DE COSTOS -->
              <v-col cols="12" md="12" class="p-0 ">
                <v-data-table
                  :headers="centroCostosHeaders"
                  :items="centroCostosItems"
                  height="100%"
                  fixed-header
                  class="elevation-1"
                  hide-default-footer
                  dense
                  item-key="id"
                >
                  <template v-slot:top>
                    <v-row>
                      <v-col cols="12" md="6" class="pt-2 pb-0">
                        <span class="primary--text font-weight-medium py-0 pl-2"
                          >Centros de costos</span
                        >
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:[`item.importe`]="{ item }">
                    <span>{{ helpers.floatToMoneyString(item.importe) }}</span>
                  </template>
                  <template v-slot:footer>
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                        class="pt-1 pb-2 pl-0 pr-6 text-right"
                      >
                        <span class="font-weight-medium text-right">
                          Total centros de costos:
                          {{
                            helpers.floatToMoneyString(
                              totalCentrosDeCostos,
                              monedaSelected != null &&
                                monedaSelected.id != monedaPorDefecto
                                ? siglaMonedaPorDefecto
                                : ""
                            )
                          }}</span
                        >
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" class="p-0 pt-2">
          <v-menu
            ref="fechaVencimientoCae"
            v-model="menuFechaVencimientoCae"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fechaVencimientoCaeSelected"
                label="Fecha venc. CAE/CAI"
                id="fechaVencimientoCae"
                hint="Formato DD/MM/AAAA"
                @blur="
                  fechaVencimientoCae = helpers.parseDateToIso(
                    fechaVencimientoCaeSelected
                  )
                "
                outlined
                tabindex="21"
                autocomplete="not"
                :hide-details="true"
                dense
                clearable
                v-mask="'##/##/####'"
                v-bind="attrs"
                v-on="on"
                :rules="
                  fechaVencimientoCaeSelected != null ? rules.validDate : []
                "
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaVencimientoCae"
              no-title
              @change="
                fechaVencimientoCaeSelected = helpers.formatDate(
                  fechaVencimientoCae
                )
              "
              @input="menuFechaVencimientoCae = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4" class="pl-2 p-0 pt-2">
          <v-text-field
            v-model="numeroCae"
            :hide-details="true"
            tabindex="22"
            dense
            outlined
            label="N° CAE/CAI"
            v-mask="'########################################################'"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="3" class="p-0 pl-2 pt-2">
          <v-autocomplete
            v-model="tipoEmisionSelected"
            :items="tiposEmision"
            outlined
            item-value="id"
            tabindex="23"
            :rules="rules.required"
            item-text="value"
            clearable
            dense
            label="Tipo de emisión"
            hide-details="auto"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="10" class="p-0 pt-2">
          <v-textarea
            v-model="observaciones"
            dense
            tabindex="24"
            outlined
            :rules="[rules.maxLength(observaciones, 100)]"
            rows="1"
            auto-grow
            hide-details="auto"
            label="Observaciones"
          >
          </v-textarea>
        </v-col>
        <v-col cols="12" md="2" class="p-0 pt-2 text-right">
          <v-btn
            type="submit"
            form="cargaFacturasForm"
            @click="saveFactura"
            tabindex="25"
            :disabled="
              !isFormValid ||
                !valorFacturaValido ||
                tieneAsientoContable ||
                fecContMenorFecCierre ||
                fechaVencimientoInvalida
            "
            color="primary"
          >
            Grabar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <!-- MODAL NUEVO DETALLE ALICUOTAS IVA -->
    <v-dialog
      v-model="detalleAlicuotasIvaModal"
      v-if="detalleAlicuotasIvaModal"
      @keydown.esc="toggleNuevoDetalleAlicuotasIva"
      max-width="35%"
      persistent
    >
      <EditDetalleAlicuotasIva
        :detalleAlicuotaToEdit="detalleAlicuotaToEdit"
        :itemsCount="itemsCount"
        :fechaComp="fecha"
        :letraComp="letraComprobante"
        @toggleNuevoDetalleAlicuotasIva="toggleNuevoDetalleAlicuotasIva"
        @addDetalleAlicuotaIva="addDetalleAlicuotaIva"
        @editDetalleAlicuotaIva="editDetalleAlicuotaIva"
      ></EditDetalleAlicuotasIva>
    </v-dialog>

    <!-- MODAL NUEVA IMPUTACION CONTABLE -->
    <v-dialog
      v-model="imputacionContableModal"
      v-if="imputacionContableModal"
      @keydown.esc="toggleNuevoImputacionContable"
      max-width="50rem"
      persistent
    >
      <ImputacionCbleCentroCostosCargaFactura
        :EntFacId="entFacId"
        :empresaIdConta="empresaIdConta"
        :ProvId="provId"
        :datosImputacionContable="datosImputacionContable"
        :totalImputable="totalImputable"
        :imputacionContableItemsProp="imputacionContableItemsProp"
        :centroCostosItemsProp="centroCostosItemsProp"
        @toggleNuevoImputacionContable="toggleNuevoImputacionContable"
        @reloadTables="reloadTablesImputacionContable"
      ></ImputacionCbleCentroCostosCargaFactura>
    </v-dialog>
    <!-- MODAL BUSCAR PROVEEDOR -->
    <v-dialog
      v-model="buscarProveedorModal"
      v-if="buscarProveedorModal"
      @keydown.esc="toggleModalBusquedaProv"
      max-width="70%"
      persistent
    >
      <BuscarProveedor
        @toggleModalBusquedaProv="toggleModalBusquedaProv"
        @findProveedor="findProveedor"
      ></BuscarProveedor>
    </v-dialog>
    <!-- ConfirmDialog -->
    <v-dialog
      v-model="confirmDialogExpediente"
      max-width="400"
      @keydown.esc="toggleConfirmDialog"
      persistent
    >
      <v-card>
        <v-card-title class="headline primary--text">Atención</v-card-title>
        <v-card-text class="text-left">{{ confirmDialogText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="volver(true)"> NO</v-btn>
          <v-btn text color="primary" dark @click="toggleConfirmDialog"
            >SI</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MODAL PERCEPCIONES IIBB -->

    <v-dialog
      v-model="modalPercIIBB"
      v-if="modalPercIIBB"
      @keydown.esc="toggleModalPercIIBB"
      max-width="50%"
      persistent
    >
      <v-container fluid class="p-0">
        <v-card class="p-2 pr-4">
          <v-row>
            <v-col cols="12" class="pl-4 py-0">
              <span class="primary--text py-0 pl-2 font-weight-medium"
                >Percepciones de IIBB</span
              >
            </v-col>
          </v-row>
          <v-card-text>
            <v-form v-model="isFormPercIIBBValid" ref="jurisdiccionesIIBBForm">
              <v-row>
                <v-col cols="12" md="7" class="px-2 pt-0 pb-2">
                  <v-autocomplete
                    v-model="selectedJurisdiccion"
                    :items="jurisdicciones"
                    item-value="jurIbId"
                    item-text="jurIbNombre"
                    label="Jurisdicción IIBB"
                    outlined
                    return-object
                    dense
                    :rules="rules.required"
                    :hide-details="true"
                    clearable
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" class="px-2 py-0">
                  <currency-input
                    v-model="importeItemPercIIBB"
                    label="Importe"
                    :options="currencyOptions"
                    :rules="[rules.decimalGreaterThanZero(importeItemPercIIBB)]"
                    :readonly="false"
                    :hideDetails="'true'"
                  ></currency-input>
                </v-col>
                <v-col cols="2" md="2" class="px-2 pt-1 pb-0 text-right">
                  <v-btn
                    small
                    color="primary"
                    form="jurisdiccionesIIBBForm"
                    @click="addJurisdiccion"
                    type="submit"
                    :disabled="!isFormPercIIBBValid"
                  >
                    {{ internIdPercIIBB != null ? "Guardar" : "Agregar" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <v-data-table
              :headers="headersPercIIBB"
              :items="itemsPercIIBB"
              item-key="id"
              class="elevation-1"
              hide-default-footer
              dense
            >
              <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="editPercIIBB(item)"
                    >
                      {{ editIcon }}
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="removeItem(item)"
                    >
                      {{ deleteIcon }}
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.importe`]="{ item }">
                <span>{{ helpers.floatToMoneyString(item.importe) }}</span>
              </template>
              <template v-slot:footer>
                <v-row>
                  <v-col cols="12" md="6" class="py-0 pl-0 pr-6 text-right">
                    <span class="font-weight-medium text-right">
                      Total :
                      {{ helpers.floatToMoneyString(totalPercIIBB) }}</span
                    >
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-row>
              <v-col cols="12" class="py-0 pr-4 text-right">
                <v-btn color="primary" small @click="toggleModalPercIIBB">
                  Salir
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>
    <ConfirmDialog
      :openConfirm.sync="confirmDialogFacEnCero"
      :text="confirmDialogText"
      :maxWidth="'40rem'"
      :title="'¿Desea grabar un comprobante con importe en cero?'"
      @onConfirm="toggleConfirmDialogFacEnCero(true)"
    ></ConfirmDialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import helpers from "@/utils/helpers.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import ImputacionCbleCentroCostosCargaFactura from "@/views/modules/proveedores/cuentaCorriente/ImputacionCbleCentroCostosCargaFactura.vue";
import EditDetalleAlicuotasIva from "@/views/modules/proveedores/cuentaCorriente/EditDetalleAlicuotasIva.vue";
import BuscarProveedor from "@/components/modules/proveedores/BuscarProveedor.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

export default {
  name: "CargaFacturasProveedores",
  directives: { mask },
  components: {
    PageHeader,
    Ayuda,
    GoBackBtn,
    ImputacionCbleCentroCostosCargaFactura,
    EditDetalleAlicuotasIva,
    BuscarProveedor,
    CurrencyInput,
    ConfirmDialog
  },
  data() {
    return {
      title: "Carga de comprobante",
      editIcon: enums.icons.EDIT,
      searchIcon: enums.icons.SEARCH,
      infoIcon: enums.icons.SNB_INFO,
      deleteIcon: enums.icons.DELETE,
      volverIcon: enums.icons.BACK,
      helpers: helpers,
      calendarIcon: enums.icons.CALENDAR,
      rules: rules,
      routeToGo: "CuentaCorrienteProveedores",
      datosImputacionContable: null,
      empresaIdConta: null,
      empresaMultijurisdiccion: false,
      confirmDialogExpediente: false,
      tieneAsientoContable: false,
      fecContMenorFecCierre: false,
      numeroExpediente: 0,
      confirmDialogText: "",
      // form fields
      movProvId: null,
      empresas: [],
      empresaSelected: null,
      entFacId: null,
      provCod: "",
      provNom: null,
      proveedorSelected: null,
      provId: null,
      plazoPagoProveedor: 1,
      proveedores: [],
      proveedorAutocompleteInput: null,
      proveedoresLoading: false,
      domicilio: "",
      condIva: "",
      cuit: "",
      numeroComprobante: null,
      sucursalComprobante: null,
      letraComprobante: null,
      tieneNumeradorAutomatico: false,
      tipoOperacionSelected: null,
      tiposOperacion: [],
      tipoComprobanteSelected: null,
      tipoComprobanteNom: null,
      tipoCompLlevaImpContable: false,
      tiposComprobantes: [],
      cotizacionLoading: false,
      cotizacion: 0,
      otrosConceptos: 0,
      impInternos: 0,
      percepcionIIBB: 0,
      percepcionIVA: 0,
      isFormValid: false,
      parametrosGeneralesProv: null,
      numeroCae: null,
      tiposEmision: [
        {
          id: "E",
          value: "Electrónico"
        },
        {
          id: "I",
          value: "Impreso"
        }
      ],
      tipoEmisionSelected: "E",
      observaciones: "",
      monedas: [],
      monedaSelected: null,
      bloqueaMoneda: false,
      siglaMonedaPorDefecto: "",
      monedaPorDefecto: null,
      currencyOptions: {
        locale: "nl-NL",
        currency: "USD",
        currencyDisplay: "narrowSymbol",
        valueRange: {
          min: 0,
          max: 999999999999999
        },
        precision: undefined,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        exportValueAsInteger: false,
        autoSign: true,
        useGrouping: true
      },
      //  banderas de modals
      confirmDialogFacEnCero: false,
      flagConfirmFacEnCero: false,
      detalleAlicuotasIvaModal: false,
      imputacionContableModal: false,
      buscarProveedorModal: false,
      centroCostoModal: false,
      modalPercIIBB: false,
      // datos detalle alicuotas iva
      isFormPercIIBBValid: false,
      detalleAlicuotaToEdit: null,
      selectedJurisdiccion: null,
      jurisdicciones: [],
      headersPercIIBB: [
        { text: "Jurisdicción", value: "jurIbNombre", align: "start" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "Acciones", value: "acciones", align: "end", sortable: false }
      ],
      itemsPercIIBB: [],
      importeItemPercIIBB: null,
      internIdPercIIBB: null,
      itemsCount: null,
      totalCentrosDeCostos: 0,
      totalImputacionContable: 0,
      // fechas
      fecha: null,
      menuFecha: null,
      fechaSelected: null,
      fechaVencimiento: null,
      menuFechaVencimiento: null,
      fechaVencimientoSelected: null,
      fechaContable: null,
      menuFechaContable: null,
      fechaContableSelected: null,
      periodo: null,
      fechaVencimientoCae: null,
      menuFechaVencimientoCae: null,
      fechaVencimientoCaeSelected: null,
      fechaInicioEjercicio: null,
      fechaFinEjercicio: null,
      //parametros generales
      parametroGeneralBloqueaLetraComp: false,
      parametroGeneralMultimoneda: false,
      permitirCargarFacCeroProv: false,
      validarFecCbleFacProv: false,
      movProvFacConDetIva: false,
      imputCbleIVA: false,
      imputCbleImpInt: false,
      imputCbleOtros: false,
      // data table arrays
      detalleAlicuotasIvaItems: [],
      imputacionContableItems: [],
      centroCostosItems: [],
      imputacionContableItemsProp: [],
      centroCostosItemsProp: [],
      // data table headers
      imputacionContableHeaders: [
        {
          text: "Cuenta",
          sortable: false,
          value: "cuenta",
          align: "start"
        },
        {
          text: "Descripción",
          sortable: false,
          value: "descripcion",
          align: "start"
        },
        {
          text: "Importe",
          sortable: false,
          value: "importe",
          align: "end"
        }
      ],
      centroCostosHeaders: [
        {
          text: "Nombre",
          sortable: false,
          value: "nombre",
          align: "start"
        },
        {
          text: "Importe",
          sortable: false,
          value: "importe",
          align: "end"
        }
      ],
      detalleAlicuotasIvaHeaders: [
        {
          text: "Concepto",
          sortable: false,
          value: "concepto",
          align: "start"
        },
        {
          text: "Alícuota IVA",
          sortable: false,
          value: "alicuotaIVA",
          align: "start"
        },
        {
          text: "% IVA",
          sortable: false,
          value: "ivaPorcentaje",
          align: "end"
        },
        {
          text: "Neto",
          sortable: false,
          value: "neto",
          align: "end"
        },
        {
          text: "IVA",
          sortable: false,
          value: "iva",
          align: "end"
        },
        {
          text: "Total",
          sortable: false,
          value: "total",
          align: "end"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          width: "12%",
          align: "end"
        }
      ]
    };
  },

  async created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    await this.setParametrosGeneralesProveedores();
    await this.setCombos();
    this.movProvId = this.$route.params.mProvId ?? null;
    if (this.movProvId != null) {
      await this.setDatosFacturaProveedorEdit();
    } else {
      this.setFechas();
      await this.setEmpresaProveedor(
        this.$route.params.empresaSelected,
        this.$route.params.provCod
      );
    }
    // await this.getParametroGralCargaFacturaEnCero();
  },
  mounted() {
    this.focusRefByParam("btnComboEmpresas");
  },
  computed: {
    siglaMonedaSelected() {
      if (
        this.parametroGeneralMultimoneda &&
        this.monedaSelected != null &&
        this.monedaSelected.id != this.monedaPorDefecto
      ) {
        return this.monedaSelected.value2;
      } else {
        return "";
      }
    },
    totalPercIIBB() {
      return this.itemsPercIIBB.length > 0
        ? this.itemsPercIIBB.reduce(
            (acc, item) => acc + parseFloat(item.importe),
            0
          )
        : 0;
    },
    hasValidationError() {
      // Verificar reglas para el campo 'periodo'
      const periodoInvalid = this.rules.periodoYyyyMm.some(
        rule => rule(this.periodo) !== true
      );
      // Verificar reglas para 'fechaSelected', 'fechaVencimientoSelected', y 'fechaContableSelected'
      const fechasInvalid = [
        "fechaSelected",
        "fechaVencimientoSelected",
        "fechaContableSelected"
      ].some(fecha => {
        return (
          this.rules.validDate.some(
            rule => this[fecha] != null && rule(this[fecha]) !== true
          ) ||
          this.rules.required.some(
            rule => this[fecha] != null && rule(this[fecha]) !== true
          )
        );
      });

      return periodoInvalid || fechasInvalid;
    },
    totalImputable() {
      let suma = this.netoDetallesAlicuotasIva;
      if (this.imputCbleIVA) {
        suma += this.ivaDetallesAlicuotasIva;
      }
      if (this.imputCbleImpInt) {
        suma += this.impInternos;
      }
      if (this.imputCbleOtros) {
        suma += this.otrosConceptos;
      }
      if (
        this.monedaSelected != null &&
        this.monedaPorDefecto != this.monedaSelected.id
      ) {
        return suma * this.cotizacion;
      } else {
        return suma;
      }
    },
    netoDetallesAlicuotasIva: {
      get: function() {
        if (this.detalleAlicuotasIvaItems.length > 0) {
          return this.detalleAlicuotasIvaItems.reduce((total, detalle) => {
            if (detalle.neto !== null) {
              return total + detalle.neto;
            } else {
              return total;
            }
          }, 0);
        } else {
          return 0;
        }
      },
      set: function() {
        return null;
      }
    },
    ivaDetallesAlicuotasIva: {
      get: function() {
        if (this.detalleAlicuotasIvaItems.length > 0) {
          return this.detalleAlicuotasIvaItems.reduce((total, detalle) => {
            if (detalle.neto !== null) {
              return total + detalle.iva;
            } else {
              return total;
            }
          }, 0);
        } else {
          return 0;
        }
      },
      set: function() {
        return null;
      }
    },
    valorFacturaValido() {
      let facturaEnCero =
        this.permitirCargarFacCeroProv && this.totalDetallesAlicuotasIva == 0;
      let facturaConValor = this.totalDetallesAlicuotasIva > 0;
      return facturaEnCero || facturaConValor;
    },
    fechaVencimientoInvalida() {
      if (this.fechaVencimiento != null && this.fecha != null) {
        return new Date(this.fechaVencimiento) < new Date(this.fecha);
      } else return true;
    },
    totalDetallesAlicuotasIva() {
      if (this.detalleAlicuotasIvaItems.length > 0) {
        let suma = 0;
        suma = this.detalleAlicuotasIvaItems.reduce((total, detalle) => {
          if (detalle.total !== null) {
            return total + detalle.total;
          } else {
            return total;
          }
        }, 0);
        suma +=
          (this.empresaMultijurisdiccion
            ? this.totalPercIIBB
            : this.percepcionIIBB) +
          this.percepcionIVA +
          this.impInternos +
          this.otrosConceptos;

        return suma;
      } else {
        return 0;
      }
    }
  },
  watch: {
    fechaContable(val) {
      if (val != null) {
        const parts = val.split("-");
        const fechaContable = new Date(parts[0], parts[1] - 1, parts[2]);
        fechaContable.setHours(0, 0, 0, 0);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const valida =
          (fechaContable < today || fechaContable > today) &&
          this.validarFecCbleFacProv;
        if (valida) {
          this.alertValidacionFechaContable();
        }
        this.setImputacionContableEmpresa();
      }
    },
    fecha() {
      if (this.movProvId == null) {
        this.updateFechas();
      }
    },
    proveedorAutocompleteInput(val) {
      if (val && val.length >= 3) {
        this.getProveedoresFilter();
      }
    }
  },
  methods: {
    ...mapActions({
      fetchProveedoresByQuery: "proveedores/fetchProveedoresByQuery",
      saveFacturaProveedores: "proveedores/saveFacturaProveedores",
      getPlazoPagoProveedorById: "proveedores/getPlazoPagoProveedorById",
      getFacturaDetalleCompletoProv:
        "proveedores/getFacturaDetalleCompletoProv",
      getJurisdiccionesIIBBProveedor:
        "proveedores/getJurisdiccionesIIBBProveedor",
      getParametrosGeneralesProveedorCargaFactura:
        "proveedores/getParametrosGeneralesProveedorCargaFactura",
      getLetraTipoCompProveedor: "proveedores/getLetraTipoCompProveedor",
      getTipoOperacion: "taxonomy/getTipoOperacion",
      getMonedaDeProveedor: "proveedores/getMonedaDeProveedor",
      getParametroGralCargaFacturaEnCero:
        "taxonomy/getParametroGralCargaFacturaEnCero",
      getEntidadesFacturantes: "proveedores/getEntidadesFacturantes",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getCotizacionMoneda: "AdministracionSistema/getCotizacionMoneda",
      getTiposComprobantesProveedores:
        "proveedores/getTiposComprobantesProveedores",
      getTipoComprobanteProveedorById:
        "proveedores/getTipoComprobanteProveedorById",
      getImputContablesByEmpresaxFechaContable:
        "proveedores/getImputContablesByEmpresaxFechaContable",
      getProveedoresByCod: "afiliaciones/getProveedoresByCod",
      getTipoCompTieneNumAutomatico:
        "proveedores/getTipoCompTieneNumAutomatico",
      setAlert: "user/setAlert"
    }),
    async getCotizacion() {
      this.cotizacionLoading = true;
      const payload = {
        monedaId: this.monedaSelected.id,
        fechaComprobante: this.fecha
      };
      try {
        const response = await this.getCotizacionMoneda(payload);
        this.cotizacion = response;
      } catch (error) {
      } finally {
        this.cotizacionLoading = false;
      }
    },
    alertValidacionFechaContable() {
      this.setAlert({
        type: "warning",
        message: "La fecha contable no puede ser distinta a la fecha de hoy"
      });
      let today = new Date();
      let año = today.getFullYear();
      let mes = today.getMonth() + 1;
      let dia = today.getDate();

      dia < 10 ? (dia = `0${dia}`) : dia;
      mes < 10 ? (mes = `0${mes}`) : mes; //para que el mes tenga dos digitos y lo reconozca el datepicker
      let date = `${año}-${mes}-${dia}`;
      let dateFormated = this.helpers.formatDate(date);
      this.fechaContable = date;
      this.fechaContableSelected = dateFormated;
    },
    async getMonedaProveedor() {
      if (this.provId != null) {
        const response = await this.getMonedaDeProveedor(this.provId);
        if (response != null) {
          this.monedaSelected = this.monedas.find(x => x.id == response.id);
          this.bloqueaMoneda = response.haveCompAsignados;
          if (this.movProvId == null) {
            this.getCotizacion();
          }
        }
      } else {
        this.bloqueaMoneda = false;
      }
    },

    async setCombos() {
      this.empresas = await this.getEntidadesFacturantes();
      if (this.empresas.length == 1) {
        this.empresaSelected = this.empresas[0];
      }
      this.tiposOperacion = await this.getTipoOperacion();
      this.monedas = await this.getMonedasTaxonomy("habListado");
      let monedaPorDefecto = this.monedas.filter(x => x.bool == true);
      this.monedaSelected = monedaPorDefecto[0];
      this.monedaPorDefecto = monedaPorDefecto[0].id;
      this.siglaMonedaPorDefecto = this.parametroGeneralMultimoneda
        ? monedaPorDefecto[0].value2
        : "";
    },
    async setParametrosGeneralesProveedores() {
      const parametrosGen = await this.getParametrosGeneralesProveedorCargaFactura();
      this.parametroGeneralMultimoneda = parametrosGen.multimoneda;
      this.parametroGeneralBloqueaLetraComp =
        parametrosGen.bloquearLetraCompProv;
      this.permitirCargarFacCeroProv = parametrosGen.permitirCargarFacCeroProv;
      this.validarFecCbleFacProv = parametrosGen.validarFecCbleFacProv;
      this.movProvFacConDetIva = parametrosGen.movProvFacConDetIva;
      this.imputCbleIVA = parametrosGen.imputCbleIVA;
      this.imputCbleImpInt = parametrosGen.imputCbleIMPINT;
      this.imputCbleOtros = parametrosGen.imputCbleOTROS;
    },
    async setEmpresaProveedor(entFacId, provCod) {
      if (entFacId != null) {
        this.empresaSelected = this.empresas.find(x => x.id == entFacId);
        this.setDatosEmpresa();
        this.setImputacionContableEmpresa();
        this.getTiposComprobantes();
        if (this.movProvId == null) {
          this.getLetraComprobanteByProvEntFacId();
        }
      }
      if (provCod != null) {
        this.provCod = provCod;
        await this.buscarProveedor();
      }
    },
    async setDatosFacturaProveedorEdit() {
      this.title = "Edición de comprobante";
      const response = await this.getFacturaDetalleCompletoProv(this.movProvId);

      this.tipoComprobanteSelected = response.tipoCompId;
      this.tipoComprobanteNom = response.tipoCompNombre;
      this.tieneNumeradorAutomatico = response.tipoCompNumeradorAuto;
      this.tipoCompLlevaImpContable = response.tipoCompLlevaImputCble;
      // fecha
      this.fechaSelected = response.fecha;
      let partesFechaToFomat = this.fechaSelected.split("/");
      this.fecha = `${partesFechaToFomat[2]}-${partesFechaToFomat[1]}-${partesFechaToFomat[0]}`;
      // fecha venc.
      this.fechaVencimientoSelected = response.fechaVenc;
      let partesFechaVencToFomat = this.fechaVencimientoSelected.split("/");
      this.fechaVencimiento = `${partesFechaVencToFomat[2]}-${partesFechaVencToFomat[1]}-${partesFechaVencToFomat[0]}`;
      // fecha contable
      this.fechaContableSelected = response.fechaCont;
      let partesFechaContableToFomat = this.fechaContableSelected.split("/");
      this.fechaContable = `${partesFechaContableToFomat[2]}-${partesFechaContableToFomat[1]}-${partesFechaContableToFomat[0]}`;
      // fecha venc. CAE
      if (response.fechaVenCAE != null) {
        this.fechaVencimientoCaeSelected = response.fechaVenCAE;
        let partesFechaVencCaeToFomat = this.fechaVencimientoCaeSelected.split(
          "/"
        );
        this.fechaVencimientoCae = `${partesFechaVencCaeToFomat[2]}-${partesFechaVencCaeToFomat[1]}-${partesFechaVencCaeToFomat[0]}`;
      }
      /////////////////
      this.periodo = response.periodo;
      this.letraComprobante = response.letra;
      this.sucursalComprobante = response.sucursal;
      this.numeroComprobante = response.numero;
      this.tipoOperacionSelected = response.tipoOperaId;
      this.monedaSelected = this.monedas.filter(
        x => x.id == response.monedaId
      )[0];
      this.cotizacion = response.cotizacion;

      this.observaciones = response.observaciones;
      this.numeroCae = response.nroCAE;
      this.tipoEmisionSelected = response.tipoEmision;
      this.percepcionIVA = response.percIVA;
      this.percepcionIIBB = response.percIB;
      this.impInternos = response.impInternos;
      this.tieneAsientoContable = response.haveAsientoCtble;
      this.fecContMenorFecCierre = response.fecContMenorFecCierre;
      this.numeroExpediente = response.numeroExpediente;
      if (this.numeroExpediente > 0) {
        this.toggleConfirmDialog();
      }

      this.otrosConceptos = response.otrosConceptos;
      this.totalCentrosDeCostos = response.centrosCostosList.reduce(
        (sum, cc) => {
          return sum + cc.importe;
        },
        0
      );

      this.totalImputacionContable = response.imputContablesList.reduce(
        (sum, impCont) => {
          return sum + impCont.importe;
        },
        0
      );
      this.detalleAlicuotasIvaItems = response.detalleAlicuotasList.map(
        (detalle, index) => {
          return {
            ...detalle,
            internId: index + 1
          };
        }
      );
      this.itemsPercIIBB = response.detallePercepcionIB.map((percIB, index) => {
        return {
          ...percIB,
          id: index + 1
        };
      });
      this.centroCostosItems = response.centrosCostosList.map(
        (centro, index) => {
          return {
            ...centro,
            internId: index + 1
          };
        }
      );
      this.imputacionContableItems = response.imputContablesList.map(
        (imputCble, index) => {
          return {
            ...imputCble,
            internId: index + 1
          };
        }
      );
      await this.setEmpresaProveedor(response.entFacId, response.provCodigo);
    },
    async getTiposComprobantes() {
      if (this.entFacId != null) {
        const data = {
          esContado: false,
          entFacId: this.entFacId
        };
        const response = await this.getTiposComprobantesProveedores(data);
        this.tiposComprobantes = response;
      }
    },
    async setDatosTipoComprobante() {
      if (this.tipoComprobanteSelected != null) {
        const response = await this.getTipoComprobanteProveedorById(
          this.tipoComprobanteSelected
        );
        let datosTipoComprobante = response;
        this.tipoCompLlevaImpContable = response.llevaImputCble;

        await this.setImputacionContableEmpresa();
        if (datosTipoComprobante.numAutoLetra.trim().length > 0) {
          this.letraComprobante = datosTipoComprobante.numAutoLetra;
        }
        this.sucursalComprobante = datosTipoComprobante.numAutoSuc;
        await this.getTipoCompNumAutomatico(
          this.tipoComprobanteSelected,
          datosTipoComprobante.numAutoLetra,
          datosTipoComprobante.numAutoSuc
        );
        if (
          datosTipoComprobante.numAuto == true &&
          this.tieneNumeradorAutomatico == false
        ) {
          this.setAlert({
            type: "warning",
            message: `No está configurado el numerador autómatico. \n Tipo de comprobante: '${datosTipoComprobante.tCompNombre}' \nLetra: '${datosTipoComprobante.numAutoLetra}' \nSucursal: '${datosTipoComprobante.numAutoSuc}'`
          });
        }
      }
    },
    async getTipoCompNumAutomatico(tCompId, letra, sucursal) {
      if (this.movProvId == null && letra && tCompId && sucursal) {
        this.numeroComprobante = "000000000";
        if (letra == "C") {
          this.percepcionIVA = 0;
        }
        if (tCompId != null && letra != null && sucursal != null) {
          this.tieneNumeradorAutomatico = await this.getTipoCompTieneNumAutomatico(
            {
              tCompId: tCompId,
              numAutoSuc: sucursal,
              numAutoLetra: letra
            }
          );
        } else {
          this.tieneNumeradorAutomatico = false;
        }
      }
    },
    setFechas() {
      let today = new Date();
      let año = today.getFullYear();
      let mes = today.getMonth() + 1;
      let dia = today.getDate();

      dia < 10 ? (dia = `0${dia}`) : dia;
      mes < 10 ? (mes = `0${mes}`) : mes; //para que el mes tenga dos digitos y lo reconozca el datepicker
      let date = `${año}-${mes}-${dia}`;
      let dateFormated = this.helpers.formatDate(date);
      let periodoDate = date.replace(/-/g, "").substr(0, 6);

      this.fecha = date;
      this.fechaContable = date;
      this.fechaVencimiento = date;
      this.fechaSelected = dateFormated;
      this.fechaContableSelected = dateFormated;
      this.fechaVencimientoSelected = dateFormated;
      this.periodo = periodoDate;
    },
    updateFechas() {
      if (this.movProvId == null) {
        this.fechaContable = this.fecha;
        this.fechaContableSelected = this.fechaSelected;

        let fecVenCalculo = new Date(this.fecha);
        fecVenCalculo.setDate(
          fecVenCalculo.getDate() + this.plazoPagoProveedor
        );

        this.fechaVencimiento = fecVenCalculo.toISOString().substr(0, 10);
        this.fechaVencimientoSelected = this.helpers.formatDate(
          this.fechaVencimiento
        );
        this.periodo = this.fecha.replace(/-/g, "").substr(0, 6);
      }
    },
    async getLetraComprobanteByProvEntFacId() {
      if (
        this.entFacId != null &&
        this.provId != null &&
        this.movProvId == null
      ) {
        const letraResponse = await this.getLetraTipoCompProveedor({
          entFacId: this.entFacId,
          provId: this.provId
        });
        if (letraResponse != null && letraResponse.length > 0) {
          this.letraComprobante = letraResponse;
        }
      }
    },
    setDatosEmpresa() {
      if (this.empresaSelected != null) {
        this.entFacId = this.empresaSelected.id;
        this.empresaMultijurisdiccion = this.empresaSelected.esMultiJurisdiccion;
      } else {
        this.entFacId = null;
        this.empresaMultijurisdiccion = false;
      }
    },
    async setImputacionContableEmpresa() {
      if (
        this.entFacId != null &&
        this.fechaContable != null &&
        this.tipoCompLlevaImpContable
      ) {
        try {
          const data = {
            entFacId: this.entFacId,
            fechaContable: this.fechaContable
          };
          const response = await this.getImputContablesByEmpresaxFechaContable(
            data
          );
          
          this.datosImputacionContable = response;
          this.empresaIdConta = response.empresaIdConta;
          this.fechaFinEjercicio = response.fechaFinEjercicio;
          this.fechaInicioEjercicio = response.fechaInicioEjercicio;
        } catch (error) {
          this.datosImputacionContable = null;
          this.empresaIdConta = null;
        }
        // let imputacionContable = {
        //   ejeId: response.ejeId,
        //   empresaIdConta: response.empresaIdConta,
        //   idError: response.idError,
        //   mascara: response.mascara,
        //   planCtaId: response.planCtaId
        // };

        return true;
      } else {
        return false;
      }
    },
    async buscarProveedor() {
      if (
        this.provCod !== null &&
        this.provCod !== undefined &&
        this.provCod !== ""
      ) {
        try {
          this.proveedoresLoading = true;
          // if(numberProv == null)

          this.proveedores = await this.getProveedoresByCod(this.provCod);
          if (this.proveedores.length === 0) {
            this.setAlert({
              type: "warning",
              message: "Proveedor inexistente"
            });
            this.provId = null;
            this.proveedorSelected = null;
            this.provNom = null;
            this.proveedoresLoading = false;
            return;
          }
          this.proveedoresLoading = false;
          this.proveedorSelected = this.proveedores[0];
          this.provId = this.proveedorSelected.id;
          this.provNom = this.proveedorSelected.value1;
          this.cuit = this.proveedorSelected.cuit;
          this.getMonedaProveedor();
          this.getLetraComprobanteByProvEntFacId();
          this.setPlazoDePagoProveedor();
        } catch (error) {
          this.proveedoresLoading = false;
          this.proveedorSelected = null;
          this.provId = null;
          this.bloqueaMoneda = false;
        }
      } else {
        this.proveedorSelected = null;
        this.provId = null;
        this.provNom = null;
        this.bloqueaMoneda = false;
      }
    },
    async setPlazoDePagoProveedor() {
      if (this.provId != null) {
        try {
          const response = await this.getPlazoPagoProveedorById(this.provId);
          this.plazoPagoProveedor = response;
        } catch (error) {
          this.plazoPagoProveedor = 1;
        }
      }
      this.updateFechas();
    },
    findProveedor(proveedor) {
      this.proveedorSelected = proveedor;
      this.provId = this.proveedorSelected.provId;
      this.provNom = proveedor.provNom;
      this.provCod = proveedor.provCod;
      this.getMonedaProveedor();
      this.setPlazoDePagoProveedor();
      this.getLetraComprobanteByProvEntFacId();
      this.cleanArrays();
    },
    async toggleConfirmDialogFacEnCero(confirm) {
      this.confirmDialogFacEnCero = !this.confirmDialogFacEnCero;
      this.flagConfirmFacEnCero = confirm;
      if (this.flagConfirmFacEnCero) {
        await this.saveFactura();
      }
    },
    async saveFactura() {
      if (this.totalDetallesAlicuotasIva == 0 && !this.flagConfirmFacEnCero) {
        this.toggleConfirmDialogFacEnCero(false);
        return;
      }
      const factura = {
        MovProvId: this.movProvId ?? 0,
        EntFacId: this.entFacId,
        ProvId: this.provId,
        TipoCompId: this.tipoComprobanteSelected,
        Fecha: this.fecha,
        FechaVenc: this.fechaVencimiento,
        FechaCont: this.fechaContable,
        Periodo: this.periodo,
        Letra: this.letraComprobante,
        Sucursal: this.sucursalComprobante,
        Numero: this.numeroComprobante,
        TipoOperaId: this.tipoOperacionSelected,
        Observaciones: this.observaciones,
        NroCAE: this.numeroCae,
        FechaVenCAE: this.fechaVencimientoCae,
        TipoEmision: this.tipoEmisionSelected ?? 0,
        Neto: parseFloat(this.netoDetallesAlicuotasIva.toFixed(2)),
        Iva: parseFloat(this.ivaDetallesAlicuotasIva.toFixed(2)),
        Total: parseFloat(this.totalDetallesAlicuotasIva.toFixed(2)),
        PercIVA: parseFloat(this.percepcionIVA.toFixed(2)),
        PercIB: this.empresaMultijurisdiccion
          ? parseFloat(this.totalPercIIBB.toFixed(2))
          : this.percepcionIIBB,
        ImpInternos: parseFloat(this.impInternos.toFixed(2)),
        OtrosConceptos: parseFloat(this.otrosConceptos.toFixed(2)),
        MonedaId: this.monedaSelected.id,
        Cotizacion: this.cotizacion,
        EsNumeradorAuto: this.tieneNumeradorAutomatico,
        DetalleAlicuotasList: this.detalleAlicuotasIvaItems,
        ImputContablesList: this.imputacionContableItems,
        CentrosCostosList: this.centroCostosItems,
        DetallePercepcionIB: this.itemsPercIIBB
      };
      const response = await this.saveFacturaProveedores(factura);
      if (response == true) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.volver(false);
      }
    },
    volver(vuelveSinGuardar) {
      if (vuelveSinGuardar) {
        this.$router.push({
          name: this.routeToGo,
          params: {
            empresaSelected: this.empresaSelected,
            proveedorSelected: this.proveedorSelected,
            fechaDesde: this.$route.params.fechaDesde,
            fechaHasta: this.$route.params.fechaHasta
          }
        });
      } else {
        this.$router.push({
          name: this.routeToGo,
          params: {
            empresaSelected: this.empresaSelected,
            proveedorSelected: this.proveedorSelected
          }
        });
      }
    },
    toggleNuevoDetalleAlicuotasIva() {
      this.itemsCount = this.detalleAlicuotasIvaItems.length;
      this.detalleAlicuotasIvaModal = !this.detalleAlicuotasIvaModal;
      if (!this.detalleAlicuotasIvaModal) {
        this.$nextTick(() => {
          this.detalleAlicuotaToEdit = null;
          this.focusBtnNuevoDetalleAlicuotasIva();
        });
      }
    },
    focusBtnNuevoDetalleAlicuotasIva() {
      this.$refs["btnToggleNuevoDetalleAlicuotasIva"].$el.focus();
    },
    focusRefByParam(ref) {
      document.querySelector(`#${ref}`).focus();
    },
    editDetalleAlicuotaIva(detalle) {
      let item = this.detalleAlicuotasIvaItems.find(
        x => x.internId == detalle.internId
      );
      let itemIndex = this.detalleAlicuotasIvaItems.indexOf(item);
      let editedItem = {
        ...this.detalleAlicuotasIvaItems[itemIndex],
        ...{
          alicuotaIva: detalle.alicuotaIVA,
          concepto: detalle.concepto,
          iva: detalle.iva,
          neto: detalle.neto,
          ivaPorcentaje: detalle.ivaPorcentaje,
          total: detalle.total
        }
      };
      this.detalleAlicuotasIvaItems.splice(itemIndex, 1, editedItem);
    },
    addDetalleAlicuotaIva(detalle) {
      this.detalleAlicuotasIvaItems.push(detalle);
    },
    editDetalleAlicuota(item) {
      this.detalleAlicuotaToEdit = item;
      this.itemsCount = this.detalleAlicuotasIvaItems.length;
      this.toggleNuevoDetalleAlicuotasIva();
    },
    deleteDetalleAlicuota(item) {
      this.detalleAlicuotasIvaItems = this.detalleAlicuotasIvaItems.filter(
        x => x.internId !== item.internId
      );
    },
    async toggleModalPercIIBB() {
      this.modalPercIIBB = !this.modalPercIIBB;
      if (this.modalPercIIBB) {
        const data = {
          entFacId: this.entFacId,
          provId: this.provId,
          fecha: this.fecha
        };
        this.jurisdicciones = await this.getJurisdiccionesIIBBProveedor(data);
      } else {
        this.$nextTick(() => {
          document.querySelector(`#impInternos`).focus();
        });
      }
    },
    addJurisdiccion() {
      const exists = this.itemsPercIIBB.some(
        item =>
          item.jurIbId === this.selectedJurisdiccion.jurIbId &&
          item.id != this.internIdPercIIBB
      );

      if (exists) {
        this.setAlert({
          type: "warning",
          message: "La jurisdicción seleccionada ya existe en la lista."
        });
        return;
      }
      if (this.internIdPercIIBB != null) {
        // Encuentra el índice del elemento a actualizar
        const index = this.itemsPercIIBB.findIndex(
          item => item.id === this.internIdPercIIBB
        );

        if (index !== -1) {
          // Actualiza el elemento en el array usando $set para asegurar la reactividad
          this.$set(this.itemsPercIIBB, index, {
            id: this.internIdPercIIBB,
            jurIbId: this.selectedJurisdiccion.jurIbId,
            jurIbNombre: this.selectedJurisdiccion.jurIbNombre,
            importe: this.importeItemPercIIBB
          });

          // Resetea la variable de internId para futuras adiciones
          this.internIdPercIIBB = null;

          this.setAlert({
            type: "success",
            message: "El registro ha sido actualizado correctamente."
          });
        } else {
          this.setAlert({
            type: "warning",
            message: "No se encontró el registro a actualizar."
          });
        }
      } else {
        const maxInternId =
          this.itemsPercIIBB.length > 0
            ? Math.max(...this.itemsPercIIBB.map(item => item.id))
            : 0;
        const newItem = {
          id: maxInternId + 1,
          jurIbNombre: this.selectedJurisdiccion.jurIbNombre,
          jurIbId: this.selectedJurisdiccion.jurIbId,
          importe: this.importeItemPercIIBB
        };
        this.$set(this.itemsPercIIBB, this.itemsPercIIBB.length, newItem);
        this.setAlert({
          type: "success",
          message: "El registro ha sido agregado correctamente."
        });
        // this.itemsPercIIBB.push(newItem);
      }
      this.importeItemPercIIBB = null;
      this.$refs["jurisdiccionesIIBBForm"].reset();
    },
    removeItem(item) {
      this.itemsPercIIBB = this.itemsPercIIBB.filter(i => i.id !== item.id);
    },
    editPercIIBB(itemToEdit) {
      this.selectedJurisdiccion = this.jurisdicciones.find(
        x => x.jurIbId == itemToEdit.jurIbId
      );

      this.importeItemPercIIBB = itemToEdit.importe;
      this.internIdPercIIBB = itemToEdit.id;
    },
    reloadTablesImputacionContable(
      centrosCostos,
      imputacionContable,
      totalCentrosDeCostos,
      totalImputacionContable
    ) {
      this.centroCostosItems = centrosCostos;
      this.imputacionContableItems = imputacionContable;
      this.totalCentrosDeCostos = totalCentrosDeCostos;
      this.totalImputacionContable = totalImputacionContable;
    },
    toggleNuevoImputacionContable() {
      this.imputacionContableModal = !this.imputacionContableModal;

      if (!this.imputacionContableModal) {
        this.centroCostosItemsProp = [];
        this.imputacionContableItemsProp = [];
        this.$nextTick(() => {
          document.querySelector(`#fechaVencimientoCae`).focus();
        });
      } else {
        this.centroCostosItemsProp = this.centroCostosItems;
        this.imputacionContableItemsProp = this.imputacionContableItems;
      }
    },
    toggleNuevoCentroCostos() {
      this.centroCostoModal = !this.centroCostoModal;
    },
    toggleModalBusquedaProv() {
      this.buscarProveedorModal = !this.buscarProveedorModal;
    },
    toggleConfirmDialog() {
      this.confirmDialogExpediente = !this.confirmDialogExpediente;
      if (this.confirmDialogExpediente) {
        this.confirmDialogText = `El comprobante pertenece al expediente N°: ${this.numeroExpediente}. Los cambios no tendrán efecto en el expediente. ¿Desea modificar?`;
      }
    },
    cleanArrays() {
      if (
        this.imputacionContableItems.length > 0 ||
        this.centroCostosItems.length > 0 ||
        this.detalleAlicuotasIvaItems.length > 0
      ) {
        this.detalleAlicuotasIvaItems = [];
        this.centroCostosItems = [];
        this.imputacionContableItems = [];
        this.totalImputacionContable = 0;
        this.totalCentrosDeCostos = 0;
      }
    }
  }
};
</script>

<style scoped></style>
